import { Link as GatsbyLink } from 'gatsby';
import { string } from 'prop-types';
import React from 'react';

import { childrenType } from '../../../types/children';

export const Link = ({
  className, children, to, rel, target,
}) => (
  <GatsbyLink
    className={className}
    to={to}
    rel={rel}
    target={target}
  >
    {children}
  </GatsbyLink>
);

Link.propTypes = {
  className: string,
  children: childrenType,
  to: string.isRequired,
  rel: string,
  target: string,
};

Link.defaultProps = {
  className: undefined,
  children: null,
  rel: undefined,
  target: undefined,
};
