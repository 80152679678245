import { string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { create } from '../../../helpers/bem';

import './outbound.scss';

const bem = create('content-outbound');

export const Outbound = ({ intro, link, className }) => {
  const { t } = useTranslation();

  return (
    <div className={bem(null, null, className)}>
      <p className={bem('intro')}>{intro}</p>
      <div className={bem('link-wrap')}>
        <a
          className={bem('link')}
          href={link}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('View live website')}
        </a>
      </div>
    </div>
  );
};

Outbound.propTypes = {
  intro: string.isRequired,
  link: string.isRequired,
  className: string,
};

Outbound.defaultProps = {
  className: null,
};
