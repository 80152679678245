const ROUTES = {
  'index': '/',
  'about': '/about/',
  'articles:list': '/articles/',
  'articles:detail': '/articles/[articleSlug]/',
  'work:list': '/work/',
  'work:detail': '/work/[workSlug]/',
  'experiments': '/experiments/',
};

/**
 * Returns the route defined by a name.
 */
const receive = (name, routes) => {
  const route = routes[name];
  if (!route) {
    throw new Error(`Can not find route by name "${name}".`);
  }

  return route;
};

/**
 * Builds a url for a route by name and params. Params are dynamic parts of a
 * url that are defined in the format `[param]`. Optional params are defined by
 * a question mark at the end like `[optionalParam?]`. The params need to be
 * passed as a key/value object, optional params doesn't need to be defined in
 * this object while missing regular params will throw an error.
 */
module.exports.resolve = (name, params, routes = ROUTES) => {
  const parameters = { ...params };
  const regexp = /\/\[([a-zA-Z]*)(\??)\]/g;
  const route = receive(name, routes);
  let url = route;
  let match;

  // This mimics a .matchAll() which is not implemented in all all node and
  // browser versions.
  // eslint-disable-next-line no-cond-assign
  while ((match = regexp.exec(route)) !== null) {
    const [, key, optional] = match;
    let replace = `/${parameters[key]}`;

    if (!parameters[key]) {
      if (!optional) {
        throw new Error(`Missing param "${key}" for route "${name}".`);
      }

      replace = '';
    }

    url = url.replace(`/[${key}${optional}]`, replace);
  }

  return url;
};
