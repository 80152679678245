import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { arrayOf, string } from 'prop-types';
import React from 'react';

import { create } from '../../../helpers/bem';
import { imageType } from '../../../types/image';

import './image.scss';

const bem = create('common-image');

export const Image = ({
  image, alt, placeholder, layout, formats, objectPosition, className,
}) => (
  <div className={bem(null, null, className)}>
    <GatsbyImage
      image={getImage(image)}
      alt={alt}
      placeholder={placeholder}
      layout={layout}
      formats={formats}
      objectPosition={objectPosition}
    />
  </div>
);

Image.propTypes = {
  image: imageType.isRequired,
  alt: string.isRequired,
  placeholder: string,
  layout: string,
  formats: arrayOf(string),
  objectPosition: string,
  className: string,
};

Image.defaultProps = {
  placeholder: 'dominantColor',
  layout: 'constrained',
  formats: ['auto', 'webp', 'avif'],
  objectPosition: 'center center',
  className: undefined,
};
