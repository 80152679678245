import {
  arrayOf, number, shape, string,
} from 'prop-types';

export const imageType = shape({
  backgroundColor: string.isRequired,
  height: number.isRequired,
  images: shape({
    fallback: shape({
      sizes: string.isRequired,
      src: string.isRequired,
      srcSet: string.isRequired,
    }).isRequired,
    sources: arrayOf(
      shape({
        sizes: string.isRequired,
        srcSet: string.isRequired,
        type: string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  layout: string.isRequired,
  width: number.isRequired,
}).isRequired;
