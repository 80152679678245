import i18n from 'i18next';
import React from 'react';
import { initReactI18next } from 'react-i18next';

import { Layout } from './src/components/layout';
import { getSettings } from './src/helpers/i18n';

i18n.use(initReactI18next).init(getSettings());

export const wrapPageElement = ({ element }) => (
  <Layout>
    {element}
  </Layout>
);
