import 'what-input';
import 'wicg-inert';

import { useLocation } from '@reach/router';
import { canUseDOM } from 'exenv';
import { node } from 'prop-types';
import React, { useEffect } from 'react';

import { Footer } from '../core/footer';
import { Header } from '../core/header';
import { Navigation } from '../core/navigation';

import './layout.scss';

export const Layout = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!canUseDOM || !CSSStyleDeclaration.prototype.setProperty) {
      return () => undefined;
    }

    // This value can be accessed within the sass-function `vw()`. This helps
    // to get a more precise value of the viewport compared to the value `100vw`.
    // In CSS the value for vw also includes the width of a scrollbar which is
    // not the visual area of the viewport.
    const calculateViewports = () => {
      const vw = document.documentElement.clientWidth / 100;
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vw', `${vw}px`);
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    window.addEventListener('resize', calculateViewports);

    return () => window.addEventListener('resize', calculateViewports);
  }, []);

  return (
    <>
      {pathname !== '/' && (
      <Header>
        <Navigation />
      </Header>
      )}
      <main role="main" id="main" tabIndex="-1">
        {children}
      </main>
      {(pathname !== '/experiments/' && pathname !== '/about/' && pathname !== '/articles/' && pathname !== '/work/' && pathname !== '/') && <Footer />}
    </>
  );
};

Layout.propTypes = {
  children: node.isRequired,
};
