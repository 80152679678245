import React from 'react';
import { useTranslation } from 'react-i18next';

import { create } from '../../../helpers/bem';
import { resolve } from '../../../helpers/urls';
import { childrenType } from '../../../types/children';
import { Link } from '../../common/link';

import './header.scss';

const bem = create('header');

export const Header = ({ children }) => {
  const { t } = useTranslation();

  return (
    <header className={bem()} role="banner">
      <a className={bem('skip')} href="#main">
        {t('Skip to main content')}
      </a>
      <h1 className={bem('logo')}>
        <Link to={resolve('index')} rel="index">
          {t('Yves Rijckaert')}
        </Link>
      </h1>
      {children}
    </header>
  );
};

Header.propTypes = {
  children: childrenType.isRequired,
};
