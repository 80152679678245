import { useLocation } from '@reach/router';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { create } from '../../../helpers/bem';
import { resolve } from '../../../helpers/urls';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { Link } from '../../common/link';

import { Toggle } from './toggle';

import './navigation.scss';

const bem = create('navigation');
const MAX_SCREEN_WIDTH = 960;
const KEY_ESC = 27;

const useNavigationControls = () => {
  const wrapper = useRef(null);
  const list = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowSize();
  const onToggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);
  useEffect(() => {
    if (width <= MAX_SCREEN_WIDTH) {
      list.current.inert = !isOpen;
    }
  }, [list, isOpen, width]);

  // pathname change triggers close:
  const { pathname } = useLocation();
  useEffect(() => setIsOpen(false), [pathname, setIsOpen]);

  const handleFocusIn = useCallback(() => {
    if (!wrapper?.current || !wrapper.current.contains(document.activeElement)) {
      setIsOpen(false);
    }
  }, [setIsOpen]);

  const handleKeyDown = useCallback(({ keyCode }) => {
    switch (keyCode) {
      case KEY_ESC:
        setIsOpen(false);
        break;

      default:
        break;
    }
  }, [setIsOpen]);

  useEffect(() => {
    // close menu if screen gets bigger:
    if (width > MAX_SCREEN_WIDTH) {
      setIsOpen(false);
      list.current.inert = false;
    }
    // close menu if focus outside menu:
    document.addEventListener('focusin', handleFocusIn);
    // close menu if ESC key is pressed:
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [width, setIsOpen, handleFocusIn, handleKeyDown]);

  return {
    wrapper, list, isOpen, onToggle,
  };
};

export const Navigation = () => {
  const { t } = useTranslation();
  const {
    wrapper, list, isOpen, onToggle,
  } = useNavigationControls();

  return (
    <nav className={bem()} id="nav" role="navigation" aria-label={t('Main')} ref={wrapper}>
      <Toggle isOpen={isOpen} onToggle={onToggle} />
      <ul className={bem('list', { 'is-open': isOpen })} ref={list}>
        <li className={bem('list-item')}>
          <Link className={bem('link')} to={resolve('work:list')}>
            {t('Client work')}
          </Link>
        </li>
        <li className={bem('list-item')}>
          <Link className={bem('link')} to={resolve('experiments')}>
            {t('Experiments')}
          </Link>
        </li>
        <li className={bem('list-item')}>
          <Link className={bem('link')} to={resolve('articles:list')}>
            {t('Articles')}
          </Link>
        </li>
        <li className={bem('list-item')}>
          <Link className={bem('link')} to={resolve('about')}>
            {t('About')}
          </Link>
        </li>
      </ul>
    </nav>
  );
};
