import React from 'react';
import { useTranslation } from 'react-i18next';

import { create } from '../../../helpers/bem';
import { resolve } from '../../../helpers/urls';
import { Link } from '../../common/link';

import './footer.scss';

const bem = create('footer');
const YEAR = new Date().getFullYear();

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={bem()} role="contentinfo">
      <ul className={bem('list')}>
        <li className={bem('list-item')}>
          <Link className={bem('link')} to={resolve('work:list')}>
            {t('Client work')}
          </Link>
        </li>
        <li className={bem('list-item')}>
          <Link className={bem('link')} to={resolve('experiments')}>
            {t('Experiments')}
          </Link>
        </li>
        <li className={bem('list-item')}>
          <Link className={bem('link')} to={resolve('index')}>
            {t('Home')}
          </Link>
        </li>
        <li className={bem('list-item')}>
          <Link className={bem('link')} to={resolve('articles:list')}>
            {t('Articles')}
          </Link>
        </li>
        <li className={bem('list-item')}>
          <Link className={bem('link')} to={resolve('about')}>
            {t('About')}
          </Link>
        </li>
      </ul>
      <small className={bem('note')}>
        &copy;
        <time dateTime={YEAR}>{YEAR}</time>
        {' '}
        {t('Yves Rijckaert')}
      </small>
    </footer>
  );
};
