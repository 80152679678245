import BEMHelper from 'react-bem-helper';

/**
 * Creates a wrapped function for react-bem-helper. This allows us to use a
 * compromise between object spreads using `...bem()` (which is not allowed in
 * our eslint rules for jsx elements) and `className={bem().className}`. Instead
 * of returning an object with a propery `className`, the value of `className`
 * will be returned. This allows to write `className={bem()}`
 * @param {string|object} options the constructor options for react-bem-helper.
 * @return {function} a wrapper function for the created bem helper that returns
 *  the value of the className propery.
 */
export const create = (options) => {
  const bem = BEMHelper(options);
  return (...args) => bem(...args).className;
};
