/* eslint-disable global-require */

module.exports.getSettings = () => ({
  resources: {
    en: {
      translation: require('../translations/en.json'),
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  nsSeparator: false,
  keySeparator: false,
  returnEmptyString: false,
});
