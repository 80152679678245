import { bool, func } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { create } from '../../../../helpers/bem';
import './toggle.scss';

const bem = create('navigation__toggle');

export const Toggle = ({ isOpen, onToggle }) => {
  const { t } = useTranslation();

  return (
    <button
      className={bem(null, { 'is-pressed': isOpen })}
      type="button"
      onClick={onToggle}
      aria-pressed={isOpen}
      aria-expanded={isOpen}
      aria-controls="nav"
      aria-label={isOpen ? t('Close navigation') : t('Open navigation')}
    >
      <span className={bem('hamburger-box')}>
        <span className={bem('hamburger-inner')} />
      </span>
    </button>
  );
};

Toggle.propTypes = {
  isOpen: bool.isRequired,
  onToggle: func.isRequired,
};
